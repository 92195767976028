.footer {
    padding: 40px;
    margin-top: 120px; 
    width: 100%;
}

.footer-content {
    text-align: center;
}

@media (max-width: 768px) {
    .footer {
        margin-top:500px;
    }
}