.custom-modal .modal-content {
    padding: 20px;
}

.custom-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.customize-button {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 999; /* Ensure the button appears above other content */
    padding: 10px 20px; /* Larger padding for better touch area */
}

.button-container {
    position: fixed; /* Make it fixed so it stays in view */
    bottom: 20px; /* Distance from the bottom of the viewport */
    right: 20px; /* Distance from the right of the viewport */
    z-index: 999; 
    display: flex; /* Use flexbox for alignment */
    gap: 20px; /* Space between buttons */
}

.skip-button {
    /* Add additional styles if needed */
    margin-right:120px;
}

.customize-button {
    /* This button is already set up as needed */
    padding: 10px 20px; /* Keep padding for touch area */
}


.tip-quizzer {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    width: 100%;
    min-height: 100vh; /* Ensure it takes full viewport height */
    box-sizing: border-box;
    padding: 20px;
}

.content-container {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
}

.tip-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.tip-image {
    max-width: 100%;  /* Ensure the image width is responsive */
    height: auto;  /* Maintain the aspect ratio */
    max-height: 300px;  /* Set a max height to avoid too-tall images on mobile */
    object-fit: contain;  /* Make sure the image fits within the container without distortion */
}

/*  */

.map-container {
    width: 100%;
    height: 60vh; /* Adjust as needed */
    box-sizing: border-box;
    margin-bottom: 70px; /* Add space for the button */
}


.feedback-container {
    width: 100%; /* Ensure it takes full width */
    text-align: center;
    margin-top: 20px;
    box-sizing: border-box;
}

.green-glow {
    animation: glow-green 1.5s ease-out;
}

.red-glow {
    animation: glow-red 1.5s ease-out;
}

@keyframes glow-green {
    from {
        color: lightgreen;
    }
    to {
        color: black;
    }
}

@keyframes glow-red {
    from {
        color: red;
    }
    to {
        color: black;
    }
}



@media (max-width: 767px) {
    .content-container {
        flex-direction: column;
    }

    .tip-container {
        width: 90%; 
        margin-bottom: 15px;
    }

    .tip-image {
        max-width: 100%; 
        height: auto; 
    }

    .map-container {
        height: 300px; 
    }

    .feedback-container {
        padding: 10px;
        font-size: 14px; 
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: 10px; 
        width: 100%; 
        align-items: center; 
        padding: 0; 
    }

    .skip-button{
        margin-left:180px;
        margin-bottom:9px;
    }
    .customize-button {
        width: 30%; 
        max-width: 300px; 
        margin-bottom: 10px;
        padding: 10px; 
        text-align: center; 
    }

    .tip-quizzer {
        padding-top: 400px; 
    }
}

@media(width:768px){
    .content-container {
        justify-content: space-between; 
        align-items: flex-start;        
    }

    .map-container {
        width: 80%;   
        height: 60vh; 
        position: relative;
        left: 50px; 
    }

    .tip-container {
        width: 80%; 
        margin-right: auto; 
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .content-container {
        justify-content: space-between;
        align-items: flex-start;        
    }

    .map-container {
        width: 80%;   
        height: 60vh; 
        position: relative; 
        left: 60px;
    }

    .tip-container {
        width: 80%; 
        margin-right: auto; 
    }
}



