.navbar-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    padding-bottom: 20px; 
  }
  
  .navbar-custom::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 15%; 
    border-bottom: 1.75px solid #ddd; 
    left: 50%; 
    transform: translateX(-50%); 
  }
  